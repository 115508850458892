import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, pluck, switchMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as utc from 'dayjs/plugin/utc';
import { IAbstractServiceData, IAnyObject, IOrganizationDto } from 'smart-city-types';
import { ISort, RestService, Settings2Service, UsersService } from '@smart-city/core/services';
import { IOrganization, IResponseWithTotal, IUserInfo } from '@bg-front/core/models/interfaces';
import { BaseCrudService } from '@bg-front/core/services';
import { IMunicipality } from '../../../../dictionaries/modules/municipalities/models';
import {
  IForcesAndResourcesDto,
  IForcesAndResourcesForTable,
  IForcesAndResourcesHistory,
  IForcesAndResourcesList,
  IUserInfoForFilter,
} from '../models/interfaces';
dayjs.extend(customParseFormat);
dayjs.extend(utc);

/**
 * Сервис для работы со справочником Силы и средства
 */
@Injectable({
  providedIn: 'root',
})
export class ForcesAndResourcesService extends BaseCrudService<IForcesAndResourcesDto> {
  /** @ignore */
  constructor(
    public readonly rest: RestService,
    private readonly settings: Settings2Service,
    private readonly usersService: UsersService,
  ) {
    super({
      serviceName: 'Admin',
      entityName: 'ForcesAndResources',
    }, rest);
  }

  /** Получение Записи Силы и средства по запросу */
  public getByQuery(query: IAnyObject, attributes?: string[]): Observable<IForcesAndResourcesDto[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        attributes,
        name: 'ForcesAndResources',
        query: query,
        sort: { field: 'mo.name', direction: 'asc' },
      },
    }).pipe(pluck('data', 'items'));
  }

  /** Получение организаций для указанного вида службы
   * @param query - параметры запроса
   **/
  public getServiceTypeOrganizations(query: IAnyObject): Observable<IForcesAndResourcesForTable[]> {
    const settings = this.settings.getSettings();
    const checkTime = settings.forcesAndResourcesCheckTime.split(':');
    const time = settings.forcesAndResourcesCheckTime
      ? +dayjs().set('h', checkTime[0]).set('m', checkTime[1]).set('s', checkTime[2]) < +dayjs()
      : null;
    const states = this.settings.getConfig().forcesAndResourcesSentState?.split(', ') || [];

    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        attributes: [
            'id',
            'onDate',
            'organizationId.name',
            'manualOrganization',
            'serviceTypeId.lineNoteOrder',
            'personnel',
            'technique',
            'lifeCycleStepId.status.name',
            'lifeCycleStepId.status.sysname',
        ],
        name: 'ForcesAndResources',
        query,
        sort: { field: 'serviceTypeId.lineNoteOrder', direction: 'asc' },
      },
    }).pipe(
      map((response: IAbstractServiceData) => {
        return (response?.data?.items || []).map((item: IForcesAndResourcesDto) => {
          const today = +dayjs(item.onDate).startOf('day') === +dayjs().startOf('day');
          return {
            ...item,
            mark: today && time && !states.includes(item['lifeCycleStepId.status.sysname']),
          }
        });
      })
    );
  }

  /** Получение статистики сил и средств для уровня вида служб
   * @param query - объект запроса
   **/
  public getServiceTypeLevelStatistic(query: IAnyObject): Observable<IForcesAndResourcesList[]> {
    return this.rest.serviceRequest({
      action: 'getForcesAndResourcesStats',
      service: { name: 'Admin' },
      data: {
        groupBy: 'serviceType',
        ...query,
      }
    }).pipe(
      map((res: IAbstractServiceData) => res?.data),
    );
  }

  /** Получение статистики сил и средств для уровня МО
   * @param query - объект запроса
   **/
  public getMoLevelStatistic(query: IAnyObject): Observable<IForcesAndResourcesList[]> {
    return this.rest.serviceRequest({
      action: 'getForcesAndResourcesStats',
      service: { name: 'Admin' },
      data: {
        groupBy: 'mo',
        ...query,
      }
    }).pipe(
      map((res: IAbstractServiceData) => res?.data),
    );
  }


  /** Получение статистики сил и средств для уровня дат */
  public getDateLevelStatistic(query: IAnyObject): Observable<IForcesAndResourcesList[]> {
    return this.rest.serviceRequest({
      action: 'getForcesAndResourcesStats',
      service: { name: 'Admin' },
      data: {
        ...query,
        groupBy: 'onDate',
      },
    })
      .pipe(
        map((res: IAbstractServiceData) => res.data),
      );
  }

  /** Получение файла */
  public getLineNoteReport(data): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'getLineNoteReport',
      service: { name: 'Emergency' },
      data: { onDate: data },
    })
  }

  /** Получение списка МО для селекта */
  public getMunicipalitiesForSelect(query?: IAnyObject): Observable<IMunicipality[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Municipal',
          query: {
            ...(query ?? {}),
            active: true
          },
          attributes: ['id', 'name'],
          sort: {
            field: 'name',
            direction: 'asc',
          },
        },
      })
      .pipe(pluck('data', 'items'));
  }

  /**
   * Получение организаций для селекта
   * @param query - запрос
   * @param checkUsed - флаг, нужно ли проверять организацию на предмет предоставления данных за текущий день
   * @param currentOrg - уже выбранная организация, подставляется если входит в список организаций,
   * которые уже предоставили данные за текущий день
   **/
  public getOrganizationsForSelect(query?: IAnyObject, checkUsed: boolean = false, currentOrg?: string): Observable<IOrganization[]> {
    const utcOffset = this.settings.getConfig()?.utcOffset
    // Даты со смещением по часовому поясу из конфига
    let startOfDay = dayjs().startOf('day');
    let endOfDay = dayjs().endOf('day');
    if (utcOffset) {
      startOfDay = startOfDay.add(-utcOffset - (new Date).getTimezoneOffset()/60, 'hour');
      endOfDay = endOfDay.add(-utcOffset - (new Date).getTimezoneOffset()/60, 'hour');
    }
    // Получаем список организаций, которые уже предоставили данные за текущий день
    return (checkUsed
      ? this.getByQuery({
          onDate: {
            $lte: +endOfDay,
            $gte: +startOfDay,
          }
        }
        ,['organizationId'])
      : of([])
    ).pipe(
      switchMap((items: IForcesAndResourcesDto[]) => {
        const notAvailableOrgs = items?.map((el: IForcesAndResourcesDto) => el.organizationId) || [];
        const index = notAvailableOrgs.indexOf(currentOrg);
        if (index > -1) notAvailableOrgs.splice(index, 1);

        return this.rest.serviceRequest({
          action: 'select',
          service: { name: 'Admin' },
          entity: {
            query: {
              $and: [
                {
                  active: true,
                  provideResourcesData: true,
                  id: {
                    $nin: checkUsed ? [...new Set(notAvailableOrgs)] : [],
                  },
                },
                query ?? {}
              ]
            },
            name: 'Organizations',
            attributes: ['id', 'name'],
          },
        });
      }),
      pluck('data', 'items'),
    );
  }

  // Получение шагов ЖЦ для селекта
  public getStepsForSelect(): Observable<IOrganizationDto[]> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'LifeCycleStep',
        attributes: ['id', 'name', 'status.name'],
        query: {
          'lifeCycleId.type.sysname': 'forcesAndResourcesComposition',
        },
      },
    })
      .pipe(
        pluck('data', 'items'),
        map((items: IOrganizationDto[]) => items
          .map((item: IOrganizationDto) => ({
            ...item,
            name: item.name || item['status.name'],
          }))),
      );
  }

  /**
   * Получение информации о МО
   * @param id - ID МО
   */
  public getMunicipalityById(id: string): Observable<IMunicipality> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Municipal',
          query: { id },
        },
      })
      .pipe(pluck('data', 'items', '0'));
  }

  /**
   * Получение организации по id
   * @param id - id организации
   * @param checkUsed - флаг, нужно ли проверять организацию на предмет предоставления данных за текущий день
   */
  public getOrganizationById(id: string, checkUsed: boolean = false): Observable<IOrganization> {
    return this.getByQuery({
        onDate: {
          $lte: +dayjs().endOf('day'),
          $gte: +dayjs().startOf('day'),
        },
      },
      ['organizationId']
    )
      .pipe(
        switchMap((items: IForcesAndResourcesDto[]) => {
          const notAvailableOrgs = items?.map((el: IForcesAndResourcesDto) => el.organizationId) || [];
          if (checkUsed && notAvailableOrgs.includes(id)) return of(null);

          return this.rest
            .serviceRequest({
              action: 'select',
              service: { name: 'Admin' },
              entity: {
                name: 'Organizations',
                query: { id },
              },
            });
        }),
        pluck('data', 'items', '0')
      );
  }

  /** Получение списка видов служб для селекта */
  public getServiceTypesForSelect(): Observable<IAnyObject[]> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Directories' },
        entity: {
          name: 'LineNoteServiceTypes',
          query: { active: true },
          attributes: ['id', 'name'],
          sort: {
            field: 'name',
            direction: 'asc',
          },
        },
      })
      .pipe(pluck('data', 'items'));
  }

  /** Получение значения Видеть все организации в реестре "Состав сил и средств" */
  public getSeeRegistryOrganizationsValue(id: string): Observable<boolean> {
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: { name: 'OrgTypeParams', query: { id }, attributes: ['seeRegistryOrganizations'] },
      })
      .pipe( pluck('data', 'items', '0', 'seeRegistryOrganizations'));
  }

  /** Получение списка организаций по списку МО для селекта */
  public getOrganizationsByMunicipalForSelect(municipal: string): Observable<IOrganization[]> {
    let notAvailableOrgs: string[] = [];
    // Получаем список организаций, которые уже предоставили данные за текущий день
    return this.getByQuery({
        onDate: {
          $lte: +dayjs().endOf('day'),
          $gte: +dayjs().startOf('day'),
        },
      },
      ['organizationId'],
    ).pipe(
      switchMap((items: IForcesAndResourcesDto[]) => {
        notAvailableOrgs = items?.map((el: IForcesAndResourcesDto) => el.organizationId as string) || [];

        return this.rest.serviceRequest({
          action: 'select',
          service: { name: 'Admin' },
          entity: { name: 'Municipal', query: { municipal, active: true }, attributes: ['id'] },
        });
      }),
      map((response: IAbstractServiceData) => {
        return (response?.data?.items || []).map((item: IMunicipality) => item.id);
      }),
      switchMap((mo: string[]) => this.rest.serviceRequest({
        action: 'select',
        service: { name: 'Admin' },
        entity: {
          name: 'Organizations',
          query: {
            mo: { $in: mo },
            active: true,
            id: {
              $nin: [...new Set(notAvailableOrgs)],
            }
          },
          attributes: ['id', 'name'] },
      })),
      pluck('data', 'items'),
    );
  }


  /**
   * Получение списка журнала изменений для таблицы
   * @param query запрос
   * @param pageIndex номер страницы
   * @param pageSize кол-во записей на странице
   * @param sort сортировка
   * @returns ответ с массивом изменений
   */
  public getForcesAndResourcesJournalingForTable(
    query: IAnyObject,
    pageIndex: number,
    pageSize: number,
    sort?: ISort,
  ): Observable<IResponseWithTotal<IForcesAndResourcesHistory[]>> {
    /** Наименования атрибутов в журнале изменений */
    const journalingTitles: { [key: string]: string } = {
      id: 'Идентификатор',
      mo: 'Муниципальное образование',
      moLeader: 'Глава муниципального образования',
      moLeaderPhone: 'Телефон главы МО',
      organizationId: 'Организация',
      manualOrganization: 'Организация',
      serviceTypeId: 'Вид службы для отчета',
      organizationAddress: 'Адрес',
      organizationLeader: 'Руководитель организации',
      organizationLeaderPosition: 'Должность руководителя организации',
      organizationLeaderPhone: 'Телефон руководителя организации',
      rememberOrganization: 'Копировать данные организации',
      dispatcherEmail: 'Email диспетчера',
      note: 'Примечание',
      personnel: 'Количество личного состава',
      dispatcherName: 'ФИО Диспетчера',
      phone: 'Телефон диспетчера',
      technique: 'Количество техники в строю',
      equipmentInfo: 'Техника в строю',
      reserveTechnique: 'Количество техники в резерве',
      reserveEquipment: 'Техника в резерве',
    };
    /** Типы операций */
    const operationTypes: { [key: string]: string } = {
      insert: 'Добавление',
      update: 'Изменение',
      delete: 'Удаление',
    };
    const limit = {
      paNumber: pageIndex ?? 1,
      paSize: pageSize ?? 15,
    };
    const attributes = ['id', 'sysActionDateTime', 'sysAction', 'sysActionChanges', 'sysActionUserId'];
    return this.rest
      .serviceRequest({
        action: 'select',
        service: { name: 'Journaling' },
        entity: { name: 'Admin_ForcesAndResources', query, attributes },
        data: { isNeedTotal: true, limit, sort },
      })
      .pipe(
        map((response: IAbstractServiceData) => ({
          totalCount: response.data.totalCount ?? 0,
          items: (response.data.items || []).map((item: IAnyObject) => ({
            ...item,
            dateTime: dayjs(item.sysActionDateTime)
              .subtract((new Date).getTimezoneOffset(),'minutes')
              .format('DD.MM.YYYY HH:mm:ss'),
            sysAction: operationTypes[item.sysAction],
            sysActionChanges: Object.entries(this.convertObject(item.sysActionChanges))
              .filter(([key]: [string, IAnyObject]) => key !== 'id')
              .map(([key, value]: [string, IAnyObject]) => ({
                key: key,
                name: journalingTitles[key],
                old: this.transformValue(key, value?.old),
                new: this.transformValue(key, value?.new),
              })),
          })),
        })),
        switchMap((result: IResponseWithTotal<IAnyObject[]>) => {
          const userIds = Array.from((result.items || [])
            .reduce((acc: Set<string>, item: IAnyObject) => item.sysActionUserId ? acc.add(item.sysActionUserId) : acc, new Set()));
          const organizationIds = this.getActionValues(result.items, 'organizationId');
          const moIds = this.getActionValues(result.items, 'mo');
          const serviceTypeIds = this.getActionValues(result.items, 'serviceTypeId');
          return forkJoin([
            of(result),
            this.usersService.getUsers(
              { id: { $in: userIds } },
              { sort: { field: 'fio', direction: 'asc' }, limit: { paNumber: 0, paSize: pageSize ?? 15 } },
            ),
            this.rest.serviceRequest({
              action: 'select',
              service: { name: 'Admin' },
              entity: { name: 'Organizations', query: { id: { $in: organizationIds } } },
            }),
            this.rest.serviceRequest({
              action: 'select',
              service: { name: 'Admin' },
              entity: { name: 'Municipal', query: { id: { $in: moIds } } },
            }),
            this.rest.serviceRequest({
              action: 'select',
              service: { name: 'Directories' },
              entity: { name: 'LineNoteServiceTypes', query: { id: { $in: serviceTypeIds } } },
            }),
          ]);
        }),
        map(([result, usersResult, orgResults, moResults, serviceTypeResults]: [
          IResponseWithTotal<IAnyObject[]>,
          IResponseWithTotal<IUserInfo[]>,
          IAbstractServiceData,
          IAbstractServiceData,
          IAbstractServiceData,
        ]) => {
          const users = usersResult.items
            .map((item: IUserInfo) => ({ [item.id]: item.fio }))
            .reduce((acc: IAnyObject, item: IAnyObject) => ({ ...acc, ...item }), {});
          const organizations = orgResults.data.items
            .map((item: IAnyObject) => ({ [item.id]: item.name }))
            .reduce((acc: IAnyObject, item: IAnyObject) => ({ ...acc, ...item }), {});
          const mo = moResults.data.items
            .map((item: IAnyObject) => ({ [item.id]: item.name }))
            .reduce((acc: IAnyObject, item: IAnyObject) => ({ ...acc, ...item }), {});
          const serviceTypes = serviceTypeResults.data.items
            .map((item: IAnyObject) => ({ [item.id]: item.name }))
            .reduce((acc: IAnyObject, item: IAnyObject) => ({ ...acc, ...item }), {});
          return {
            ...result,
            items: result.items.map((item: IAnyObject) => ({
              ...item,
              sysActionUserFio: users[item.sysActionUserId] || '',
              sysActionChanges: item.sysActionChanges.map((change: IAnyObject) => {
                if (change.key === 'organizationId') {
                  change.old = organizations[change.old] || change.old;
                  change.new = organizations[change.new] || change.new;
                }
                if (change.key === 'mo') {
                  change.old = mo[change.old] || change.old;
                  change.new = mo[change.new] || change.new;
                }
                if (change.key === 'serviceTypeId') {
                  change.old = serviceTypes[change.old] || change.old;
                  change.new = serviceTypes[change.new] || change.new;
                }
                if (change.key === 'organizationAddress') {
                  change.old = change.old?.fullText;
                  change.new = change.new?.fullText;
                }
                return change;
              }),
            })),
          };
        }),
      );
  }

  /** Преобразование значения в требуемый вид
   * @param key - ключ свойства
   * @param value - значение для преобразования
   **/
  public transformValue(key: string, value: string | IAnyObject): string | IAnyObject {
    if (value === 'null' || value === undefined) return null;

    if (key === 'organizationAddress') {
      return this.convertObject(value);
    }

    if (key === 'rememberOrganization') {
      return JSON.parse(value as string) ? 'Да' : 'Нет';
    }

    return value;
  }

  /**
   * Преобразование JSON-строки в объект
   * @param source Исходная строка
   */
  private convertObject(source: string | IAnyObject): IAnyObject {
    if (typeof source === 'string') {
      let result;
      try {
        result = JSON.parse(source);
      } catch (e) {
        result = {};
      }
      return result;
    } else {
      return source;
    }
  }


  /**
   * Получение изменённых значений в записи по ключу
   * @param record Запись изменения
   * @param key Ключ поиска
   */
  private getActionValues(record: IAnyObject, key: string): string[] {
    return Array.from(record.reduce((acc: Set<string>, item: IAnyObject) => {
      const action = item.sysActionChanges.find((item: IAnyObject) => item.key === key);
      if (action?.old) {
        if (key === 'groups') {
          action.old.forEach((item: string) => acc.add(item));
        } else {
          acc.add(action.old);
        }
      }
      if (action?.new) {
        if (key === 'groups') {
          action.new.forEach((item: string) => acc.add(item));
        } else {
          acc.add(action.new);
        }
      }
      return acc;
    }, new Set()));
  }

  /**
   * Сохранение модели
   * @param model модель
   */
  public saveModel(model: IForcesAndResourcesDto): Observable<IAbstractServiceData> {
    if (model.id) {
      return this.rest.serviceRequest({
        action: 'update',
        service: { name: 'Admin' },
        entity: {
          name: 'ForcesAndResources',
          query: {
            id: model.id,
          },
        },
        data: model,
      });
    } else {
      return this.rest.serviceRequest({
        action: 'insert',
        service: { name: 'Admin' },
        entity: {
          name: 'ForcesAndResources',
        },
        data: model,
      });
    }
  }

  /**
   * Получение информации о пользователе для фильтрации
   * @param userId Идентификатор пользователя
   */
  public getInitDataForFilter(userId: string): Observable<IUserInfoForFilter> {
    return this.rest.serviceRequest({
      action: 'select',
      service: { name: 'Admin' },
      entity: {
        name: 'Users',
        query: {
          id: userId,
        },
        attributes: ['id', 'organizationId.id', 'organizationId.orgTypeParam.seeRegistryOrganizations',
          'mo.id', 'mo.municipal'],
      },
    })
      .pipe(map((result: IAbstractServiceData) => result?.data?.items?.[0]));
  }

  /**
   * Передать данные о СиС в НЦУКС
   * @param recordId - id записи СиС
   **/
  public transferToNcuks(recordId: string): Observable<IAbstractServiceData> {
    return this.rest.serviceRequest({
      action: 'sendForcesAndResources',
      service: { name: 'RiskAtlasIntegration' },
      data: recordId,
    });
  }
}
