<div nz-row [nzGutter]="[12, 12]" [nzAlign]="'middle'">
  <div *ngIf="!readOnly && !uploadDisabled" nz-col [nzSpan]="24">
    <nz-upload
      [nzAccept]="acceptTypes || availableTypes"
      [nzFileType]="availableTypes"
      [nzMultiple]="multiple"
      nzType="drag"
      [nzShowUploadList]="false"
      [nzCustomRequest]="onFileAddition.bind(this)"
      [nzBeforeUpload]="beforeUpload.bind(this)"
    >
      <i nz-icon nzType="plus"></i>
      <div *ngIf="title">{{ title }}</div>
    </nz-upload>
  </div>
  <div nz-col [nzSpan]="24" *ngFor="let item of files; let i = index">
    <div
      nz-row
      [nzGutter]="[12, 12]"
      [ngClass]="{ 'invalid-file': item.file?.status === 'error' }"
      *ngIf="item.file.isActive !== false">
      <div nz-col nzFlex="1 0">
        <div nz-row>
          <div nz-col class="file-info" [nzSpan]="24">
            <span class="file-info-text">{{ item.file.name || item.file.fileName }}</span>
          </div>
          <div nz-col class="file-info" [nzSpan]="24">
            <span class="file-info-text">
              <ng-container *ngIf="!userDisabled">
                <span class="file-info-title">Создал: </span>{{ item.userId?.fio }}
                •
              </ng-container>
              <span class="file-info-title">Размер: </span>{{ item.file.size | fileSize }}
            </span>
          </div>
        </div>
      </div>
      <div nz-col nzFlex="0 0 max-content">
        <button
          *ngIf="item.file?.uuid"
          type="button"
          nz-button
          nz-tooltip="Скачать"
          nzType="default"
          (click)="downloadFile(i)"
        >
          <i nz-icon nzType="download"></i>
        </button>
      </div>
      <div nz-col *ngIf="!readOnly && !deleteDisabled" nzFlex="0 0 max-content">
        <button nz-button nz-tooltip="Удалить" nzType="default" (click)="deleteFile(i)">
          <i nz-icon nzType="delete"></i>
        </button>
      </div>
      <div nz-col [nzSpan]="24"><hr style="margin: 0" /></div>
    </div>
  </div>
</div>
