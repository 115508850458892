<div>
  <h4 nz-typography>
    <i nz-icon nzType="field-time" nzTheme="outline"></i>
    Результаты прогноза
  </h4>
  <nz-divider></nz-divider>
  <div class="bg-row">
    <div class="bg-col d-flex d-flex-column d-flex-center">
      <h4 class="section-header fire-type-header text-danger" *ngIf="result.upperFireType">
        ВНИМАНИЕ! Верховой {{ result.upperFireType }} пожар
      </h4>
      <h4 class="section-header fire-type-header" *ngIf="!result.upperFireType">Низовой пожар</h4>
      <h4 class="section-header fire-type-header">По скорости распространения - {{ result.fireForce }}</h4>
    </div>
  </div>

  <nz-descriptions nzTitle="Скорость распространения лесного пожара" nzBordered nzSize="small" [nzColumn]="1">
    <nz-descriptions-item nzTitle="Фронт">{{ result?.frontSpeed }} м/ч</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Фланги">{{ result?.wingsSpeed }} м/ч</nz-descriptions-item>
    <nz-descriptions-item nzTitle="Тыл">{{ result?.backsideSpeed }} м/ч</nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions
    [nzTitle]="'Зона горения на ' + result?.burningTimes[i]?.burningTime + ' ч.'"
    nzBordered
    nzSize="small"
    [nzColumn]="1"
    *ngFor="let firePerimeters of result?.firePerimeters; index as i"
  >
    <nz-descriptions-item nzTitle="Периметр зоны горения">
      {{ +result?.firePerimeters[i] | commaToDot | number: '0.2-2' }} м
    </nz-descriptions-item>
    <nz-descriptions-item nzTitle="Площадь зоны горения"
      >{{ +result?.fireSizes[i] | commaToDot | number: '0.2-2' }} га</nz-descriptions-item
    >
  </nz-descriptions>

  <nz-descriptions nzTitle="Последствия лесного пожара" nzBordered nzSize="small" [nzColumn]="1">
    <nz-descriptions-item nzTitle="Степень повреждения древостоя">
      <ng-container *ngIf="result?.damageDegree">
        {{ result?.damageDegree }}
        <i
          nz-icon
          nzType="info-circle"
          nzTheme="outline"
          nz-tooltip
          [nzTooltipTitle]="result?.stateTreesText"
          [nzTooltipPlacement]="['topLeft', 'leftTop']"
        ></i>
      </ng-container>
      <ng-container *ngIf="!result?.damageDegree">
        <p>
          Расчёт характеристик повреждения древостоя невозможен, т.к. для лесных насаждений не заданы тип и средний
          диаметр деревьев
        </p>
      </ng-container>
    </nz-descriptions-item>
  </nz-descriptions>

  <nz-descriptions
    nzTitle="Количество непригодной древесины"
    nzBordered
    nzSize="small"
    [nzColumn]="1"
    *ngIf="result?.unusableTrees?.length"
  >
    <nz-descriptions-item [nzTitle]="item.name" *ngFor="let item of result?.unusableTrees">
      {{ item.value ? item.value : '-' }}
    </nz-descriptions-item>
    <nz-descriptions-item *ngIf="!result?.unusableTrees">
      Расчёт невозможен, т.к. для лесных насаждений не заданы виды древесных пород
    </nz-descriptions-item>
  </nz-descriptions>
</div>
