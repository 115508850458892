import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  AlertDialogComponent,
  BaseLayoutComponent,
  ColorPickerComponent,
  ConfirmationDialogComponent,
  DatetimeComponent,
  DeletedDialogComponent,
  DictionaryExportDialogComponent,
  EmergencyMiniCardComponent,
  ExportDialogComponent,
  FileUploadComponent,
  MapDialogComponent,
  MapDialogV2Component,
  MjpegPlayerComponent,
  MultiFileComponent,
  PolygonEditComponent,
  SignAndDownloadDialogComponent,
  SignatureDialogComponent,
  SignatureInfoDialogComponent,
  TreeSelectComponent,
} from './components';
import {
  CommaToDotPipe,
  DictionaryPipe,
  HighlightPipe,
  OrganizationPipe,
  NoCommaPipe,
  SafePipe,
  StringEndingPipe,
  BooleanToTextPipe,
  FileSizePipe,
} from '@bg-front/core/pipes';
import { ErrorTailorModule } from '@ngneat/error-tailor';
import { ScComponentsModule } from '@smart-city/core/common';
import { PltPipesModule } from '@smart-city/core/pipes';
import { ScMapsModule } from '@smart-city/maps/sc';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { InputMaskDirective } from './directives';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';

const COMPONENTS = [
  AlertDialogComponent,
  BaseLayoutComponent,
  ColorPickerComponent,
  ColorPickerComponent,
  ConfirmationDialogComponent,
  DatetimeComponent,
  DeletedDialogComponent,
  DictionaryExportDialogComponent,
  EmergencyMiniCardComponent,
  ExportDialogComponent,
  FileUploadComponent,
  InputMaskDirective,
  MapDialogComponent,
  MapDialogV2Component,
  MjpegPlayerComponent,
  MultiFileComponent,
  PolygonEditComponent,
  SignAndDownloadDialogComponent,
  SignatureDialogComponent,
  SignatureInfoDialogComponent,
  TreeSelectComponent,
];

const PIPES = [
  SafePipe,
  CommaToDotPipe,
  DictionaryPipe,
  HighlightPipe,
  NoCommaPipe,
  OrganizationPipe,
  StringEndingPipe,
  BooleanToTextPipe,
  FileSizePipe,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlatpickrModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    ScComponentsModule,
    ScMapsModule,
    ErrorTailorModule,
    PltPipesModule,
    NzModalModule,
    NzFormModule,
    NzSelectModule,
    NzTagModule,
    NzCardModule,
    NzIconModule,
    NzInputModule,
    NzButtonModule,
    RouterModule,
    NzInputModule,
    NzIconModule,
    NzUploadModule,
    NzTreeSelectModule,
    NzToolTipModule,
    NzSpaceModule,
  ],
  declarations: [COMPONENTS, PIPES],
  exports: [COMPONENTS, PIPES],
  providers: [SafePipe],
})
export class BgCoreModule {}
