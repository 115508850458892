import { Injectable } from '@angular/core';
import { IAdminMunicipalSchemaDto } from 'smart-city-types';
import { Settings2Service } from '@smart-city/core/services';

/**
 * Общий сервис содержащий разнообразные общие функции
 */
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(private readonly settings: Settings2Service) {}

  /**
   * Генерируем Svg иконку
   * @param iconArrayBuffer данные иконки выкачанные из sfs
   * @param iconColor цвет иконки
   */
  generateSvgIcon(iconArrayBuffer: ArrayBuffer, iconColor?: string): string {
    const iconContent = new TextDecoder().decode(iconArrayBuffer);
    const parser = new DOMParser();
    const iconXML = parser.parseFromString(iconContent, 'text/xml');
    const svgTag = iconXML.getElementsByTagName('svg');
    if (!!svgTag[0]) {
      // # in URLs starts a fragment identifier. In this case it must be exactly character #. To escape it: %23
      svgTag[0].setAttribute('fill', (iconColor || '').replace('#', '%23') || '%23000');
    }
    // Serialize XML and replace new lines
    return new XMLSerializer().serializeToString(iconXML).replace(/\r\n|\n|\r/gm, '');
  }

  /** Является ли пользователь админом */
  public isAdmin(): boolean {
    const moId = this.settings.currentUser.organizationId?.mo ?? this.settings.currentUser.mo?.id;
    const userMo = this.settings.allMo.find((mo: IAdminMunicipalSchemaDto) => {
      return mo.id === moId;
    });

    if (!userMo) {
      return false;
    }
    return !userMo.municipal;
  }
}
